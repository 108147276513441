import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import vClickOutside from 'click-outside-vue3'
import { createI18n } from 'vue-i18n'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import de from './langs/de'
import en from './langs/en'
import { createMetaManager } from 'vue-meta'
import vSelect from 'vue-select'
import Vue3Lottie from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'

const messages = {
  de: {
    message: de
  },
  en: {
    message: en
  }
}

const lang = JSON.parse(localStorage.getItem('lang'))
const i18n = createI18n({
  legacy: false,
  locale: lang,
  fallbackLocale: 'de',
  messages
})

createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .use(VueAxios, axios)
  .use(vClickOutside)
  .use(createMetaManager())
  .use(VueGoogleMaps, {
    load: {
      key: 'AIzaSyAZ6mJgUpdJNO_WpyynnB-ZTMqFmgKYLJc',
      libraries: 'places'
    }
  })
  .component('v-select', vSelect)
  .use(Vue3Lottie, { name: 'LottieAnimation' })
  .mount('#app')
