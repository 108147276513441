import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.VUE_APP_URL

class StickersService {
  getStickers (params: string) {
    return axios
      .get(API_URL + 'admin/encouragements' + params, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  getSticker (id: string) {
    return axios
      .get(API_URL + 'admin/encouragements/' + id, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  deleteSticker (id: string) {
    return axios
      .delete(API_URL + 'admin/encouragements/' + id, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }
}

export default new StickersService()
