import axios from 'axios'
import { string } from 'yup'
import authHeader from './auth-header'

const API_URL = process.env.VUE_APP_URL

class GeneralService {
  getLevels () {
    return axios
      .get(API_URL + 'admin/levels', { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  getSkillsByLevels (levelId: string) {
    return axios
      .get(API_URL + 'admin/levels/' + levelId + '/skills', { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  getSkills () {
    return axios
      .get(API_URL + 'admin/skills', { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }
}

export default new GeneralService()
