import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.VUE_APP_URL

class AnalyticsService {
  getAnalyticsLogActivitys (params: string) {
    return axios
      .get(API_URL + 'admin/analytics/students/log-activity' + params, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  getPaymentLogs (params: string) {
    return axios
      .get(API_URL + 'admin/analytics/payment-logs' + params, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  getAnalyticsStudentsFastLearners (params: string) {
    return axios
      .get(API_URL + 'admin/analytics/students/fast-learners' + params, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  getAnalyticsStudentsSlowMovers (params: string) {
    return axios
      .get(API_URL + 'admin/analytics/students/slow-movers' + params, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  studentsMovedUpToNextLevel (params: string) {
    return axios
      .get(API_URL + 'admin/analytics/students/moved-up-to-next-level' + params, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  timeSpentInProgram (params: string) {
    return axios
      .get(API_URL + 'admin/analytics/students/time-spent-in-program' + params, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  skillsTracking (params: string) {
    return axios
      .get(API_URL + 'admin/analytics/instructors/skills-tracking' + params, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  studentsMovedToNextLevel (params: string) {
    return axios
      .get(API_URL + 'admin/analytics/instructors/students-moved-to-next-level' + params, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  averageTimeSpentOnLevel (params: string) {
    return axios
      .get(API_URL + 'admin/analytics/instructors/average-time-spent-on-level' + params, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }
}

export default new AnalyticsService()
