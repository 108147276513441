import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.VUE_APP_URL

class DeleteAccountService {
  sendOtp (email: string) {
    return axios
      .post(API_URL + 'guest/student/send-otp', { email: email }, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  deleteStudent (otp: string) {
    return axios
      .post(API_URL + 'guest/student/delete', { otp: otp }, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }
}

export default new DeleteAccountService()
