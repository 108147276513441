import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.VUE_APP_URL

class StudentsService {
  getStudents (params: string) {
    return axios
      .get(API_URL + 'admin/students' + params, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  getStudent (id: string) {
    return axios
      .get(API_URL + 'admin/students/' + id, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  getPaymentsList (params: any) {
    return axios
      .get(API_URL + 'admin/students/' + params.id + '/payment' + params.string, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  deleteStudent (id: string) {
    return axios
      .delete(API_URL + 'admin/students/' + id, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  banStudent (id: string) {
    return axios
      .post(API_URL + 'admin/students/' + id + '/ban', {}, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  unbanStudent (id: string) {
    return axios
      .post(API_URL + 'admin/students/' + id + '/unban', {}, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  refunds (data: any) {
    return axios
      .post(API_URL + 'admin/students/' + data.id + '/payment/refunds', { period: data.period }, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  subscribe (data: any) {
    return axios
      .post(API_URL + 'admin/students/' + data.id + '/payment/plans/subscribe', { expiration_date: data.expiration_date }, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  unsubscribe (data: any) {
    return axios
      .post(API_URL + 'admin/students/' + data.id + '/payment/plans/unsubscribe', { }, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  refundsList (data: any) {
    return axios
      .get(API_URL + 'admin/students/' + data.id + '/payment/refunds' + data.string, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  getInviteCode (data: any) {
    return axios
      .get(API_URL + 'admin/invite-codes', { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  setInviteCode (data: any) {
    return axios
      .post(API_URL + 'admin/invite-codes', { instructors: data.instructors }, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  attachInstructors (data: any) {
    return axios
      .post(API_URL + 'admin/students/' + data.id + '/attach-instructors', {
        instructors: data.instructors
      }, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  detachInstructors (data: any) {
    return axios
      .post(API_URL + 'admin/students/' + data.id + '/detach-instructors', {
        instructors: data.instructors
      }, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }
}

export default new StudentsService()
