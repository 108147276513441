import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, vModelDynamic as _vModelDynamic, withDirectives as _withDirectives, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "login" }
const _hoisted_2 = { class: "card card-container" }
const _hoisted_3 = { class: "form-group login__email-button" }
const _hoisted_4 = { class: "login__icon-wrap" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "form-group" }
const _hoisted_7 = { class: "login__icon-wrap" }
const _hoisted_8 = ["type"]
const _hoisted_9 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LottieAnimation = _resolveComponent("LottieAnimation")!
  const _component_LoginIcon = _resolveComponent("LoginIcon")!
  const _component_Field = _resolveComponent("Field")!
  const _component_AlertBlock = _resolveComponent("AlertBlock")!
  const _component_PasswordIcon = _resolveComponent("PasswordIcon")!
  const _component_HidePasswordIcon = _resolveComponent("HidePasswordIcon")!
  const _component_ShowPasswordIcon = _resolveComponent("ShowPasswordIcon")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_LottieAnimation, {
          key: 0,
          animationData: require('@/assets/preloader/load.json')
        }, null, 8, ["animationData"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Form, { onSubmit: _ctx.login }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _cache[2] || (_cache[2] = _createElementVNode("label", {
                for: "email",
                class: "form-group-label"
              }, "Email ", -1)),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_LoginIcon),
                _createVNode(_component_Field, {
                  class: "form-control",
                  type: "email",
                  name: "email",
                  modelValue: this.email,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.email) = $event))
                }, null, 8, ["modelValue"]),
                (this.errorLogin)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createVNode(_component_AlertBlock, {
                        message: this.errorLogin.email
                      }, null, 8, ["message"])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _cache[3] || (_cache[3] = _createElementVNode("label", {
                for: "password",
                class: "form-group-label"
              }, "Password", -1)),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_PasswordIcon),
                _withDirectives(_createElementVNode("input", {
                  class: "form-control",
                  type: _ctx.showPassword ? 'text' : 'password',
                  name: "password",
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((this.password) = $event))
                }, null, 8, _hoisted_8), [
                  [_vModelDynamic, this.password]
                ]),
                (_ctx.showPassword)
                  ? (_openBlock(), _createBlock(_component_HidePasswordIcon, {
                      key: 0,
                      onClick: _ctx.togglePasswordVisibility,
                      class: "login__icon-show-password"
                    }, null, 8, ["onClick"]))
                  : _createCommentVNode("", true),
                (!_ctx.showPassword)
                  ? (_openBlock(), _createBlock(_component_ShowPasswordIcon, {
                      key: 1,
                      onClick: _ctx.togglePasswordVisibility,
                      class: "login__icon-show-password"
                    }, null, 8, ["onClick"]))
                  : _createCommentVNode("", true),
                (_ctx.errorLogin)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createVNode(_component_AlertBlock, {
                        message: this.errorLogin.password
                      }, null, 8, ["message"])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _cache[4] || (_cache[4] = _createElementVNode("div", { class: "form-group" }, [
              _createElementVNode("button", { class: "login__button" }, [
                _createElementVNode("span", null, "Login")
              ])
            ], -1))
          ]),
          _: 1
        }, 8, ["onSubmit"])
      ])
    ])
  ], 64))
}