<template>
  <div
    v-if="message"
    class="alert alert-danger"
    role="alert"
  >
    <div
      v-for="item in message"
      :key="item.id"
    >
      {{ item }}
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AlertBlock',
  props: {
    message: {
      type: [String, Boolean, Array],
      default: false
    }
  }
})
</script>

<style lang="scss" scoped>
.alert {
  position: relative;
  left: 10px;
  bottom: 2px;
  color: #ef5350;
  font-size: 12px;
  line-height: 16px;
  width: 100%;
  text-align: left;
  padding-top: 3px;
  z-index: 1;
  max-height: 33px;
  overflow: hidden;
}
</style>
