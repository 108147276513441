import moment from 'moment-timezone'
let timezone = moment.tz.guess()
if (timezone === 'Europe/Kiev') {
  timezone = 'Europe/Kyiv'
}
localStorage.setItem('timezone', timezone)
localStorage.setItem('timezone', timezone)
export default function authHeader () {
  const admin: '{}' = JSON.parse(localStorage.getItem('admin-sra') || '{}')
  if (admin) {
    return { Authorization: 'Bearer ' + admin, 'X-User-Timezone': timezone }
  }
}
