import AuthService from '@/services/auth.service'
import router from '@/router'

const admin: [] = JSON.parse(localStorage.getItem('admin-sra') || '{}')
const initialState = admin
  ? { status: { loggedIn: true }, admin }
  : { status: { loggedIn: false }, admin: null }

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login ({ commit }: any, admin: any) {
      return AuthService.login(admin).then(
        admin => {
          commit('loginSuccess', admin)
          return Promise.resolve(admin)
        },
        error => {
          commit('loginFailure')
          return Promise.reject(error)
        }
      )
    },
    getSchools ({ commit }: any, id: string) {
      return AuthService.getSchools(id).then(
        admin => {
          return Promise.resolve(admin)
        },
        error => {
          if (error.response.status === 401) {
            localStorage.removeItem('admin-sra')
            localStorage.removeItem('admin-sra-name')
            router.push('/')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    logout ({ commit }: any) {
      AuthService.logout()
      commit('logout')
      localStorage.removeItem('admin-sra')
      localStorage.removeItem('admin-sra-name')
    }
  },
  mutations: {
    loginSuccess (state: any, admin: any) {
      state.status.loggedIn = true
      state.admin = admin
    },
    loginFailure (state: any) {
      state.status.loggedIn = false
      state.admin = null
    },
    logout (state: any) {
      state.status.loggedIn = false
      state.admin = null
    }
  }
}
