import { createStore } from 'vuex'
import { auth } from '@/store/auth.module'
import { instructors } from '@/store/instructors.module'
import { analytics } from '@/store/analytics.module'
import { general } from '@/store/general.module'
import { students } from '@/store/students.module'
import { stickers } from '@/store/stickers.module'
import { deleteAccount } from '@/store/delete-account.module'
import { admins } from '@/store/admins.module'

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    instructors,
    analytics,
    general,
    students,
    stickers,
    deleteAccount,
    admins
  }
})
