/**
 * Utility to handle localStorage operations safely
 */

/**
 * Save an item to localStorage.
 * @param key - The key under which the value should be stored.
 * @param value - The value to store (string, object, etc.).
 */
export function setLocalStorageItem<T> (key: string, value: T): void {
  try {
    const stringValue = JSON.stringify(value)
    localStorage.setItem(key, stringValue)
  } catch (error) {
    console.error(`Failed to set item in localStorage: ${key}`, error)
  }
}

/**
   * Retrieve an item from localStorage.
   * @param key - The key of the value to retrieve.
   * @returns The parsed value or null if the key does not exist.
   */
export function getLocalStorageItem<T> (key: string): T | null {
  try {
    const item = localStorage.getItem(key)
    return item && item !== 'undefined' ? JSON.parse(item) : null
  } catch (error) {
    console.error(`Failed to get item from localStorage: ${key}`, error)
    return null
  }
}

//   /**
//    * Remove an item from localStorage.
//    * @param key - The key of the value to remove.
//    */
//   export function removeLocalStorageItem(key: string): void {
//     try {
//       localStorage.removeItem(key);
//     } catch (error) {
//       console.error(`Failed to remove item from localStorage: ${key}`, error);
//     }
//   }

//   /**
//    * Clear all items from localStorage.
//    */
//   export function clearLocalStorage(): void {
//     try {
//       localStorage.clear();
//     } catch (error) {
//       console.error('Failed to clear localStorage', error);
//     }
//   }
