import StudentsService from '@/services/delete-account.service'
import router from '@/router'

const admin: [] = JSON.parse(localStorage.getItem('admin-sra') || '{}')
const initialState = admin
  ? { status: { loggedIn: true }, admin }
  : { status: { loggedIn: false }, admin: null }

export const deleteAccount = {
  namespaced: true,
  state: initialState,
  actions: {
    sendOtp ({ commit }: any, email: string) {
      return StudentsService.sendOtp(email).then(
        admin => {
          return Promise.resolve(admin)
        },
        error => {
          console.log(error.response.status === 401, 'error.response.status')
          if (error.response.status === 401) {
            localStorage.removeItem('admin-sra')
            localStorage.removeItem('admin-sra-name')
            router.push('/')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    deleteStudent ({ commit }: any, otp: string) {
      return StudentsService.deleteStudent(otp).then(
        admin => {
          return Promise.resolve(admin)
        },
        error => {
          console.log(error.response.status === 401, 'error.response.status')
          if (error.response.status === 401) {
            localStorage.removeItem('admin-sra')
            localStorage.removeItem('admin-sra-name')
            router.push('/')
            return
          }
          return Promise.reject(error)
        }
      )
    }
  },
  mutations: {
  }
}
